<template>
  <div class="show-model">
    <Transition name="down">
      <div class="show-model-body" v-show="visible">
        <div class="left" id="iframe" :class="{ loading: iframeLoad }">
          <div class="loading-div" v-if="iframeLoad">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="30px"
              height="30px"
              viewBox="0 0 50 50"
              style="enable-background: new 0 0 50 50"
              xml:space="preserve"
            >
              <path
                fill="#FF6700"
                d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
                transform="rotate(275.098 25 25)"
              >
                <animateTransform
                  attributeType="xml"
                  attributeName="transform"
                  type="rotate"
                  from="0 25 25"
                  to="360 25 25"
                  dur="0.6s"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
          </div>
        </div>
        <div class="right">
          <h4>{{ title }}</h4>
          <div id="qrcode"></div>
          <p>手机扫描二维码体验</p>
          <button class="close-btn" @click="close">关闭</button>
        </div>
      </div>
    </Transition>
  </div>
</template>
<script setup>
import { ref, onMounted, defineProps, nextTick, watch, onUnmounted } from "vue";
const props = defineProps({
  title: {
    type: String,
    default: "活动标题",
  },
  link: {
    type: String,
    default: "www.baidu.com",
  },
});
const visible = ref(false);
const iframeLoad = ref(true);
let qrcode = ref(null)
onMounted(() => {
  nextTick(() => {
    qrcode.value = new QRCode(document.getElementById("qrcode"), {
      width: 248,
      height: 248,
    });
    qrcode.value.makeCode(props.link);
  });
});
onUnmounted(() => {
  qrcode.value.clear();
});
watch(
  () => props.title,
  (val) => {
    if (val) {
      visible.value = true;
      nextTick(() => {
        let iframe = document.createElement("iframe");
        iframe.src = props.link;
        if (iframe.attachEvent) {
          iframe.attachEvent("onload", function () {
            setTimeout(() => {
              iframeLoad.value = false;
            }, 1000);
          });
        } else {
          iframe.onload = function () {
            setTimeout(() => {
              iframeLoad.value = false;
            }, 1000);
          };
        }
        document.getElementById("iframe").appendChild(iframe);
      });
    }
  },
  { immediate: true }
);
const close = () => {
  visible.value = false;
  qrcode.value.clear();
  document.getElementById("iframe").innerHTML = "";
  document.getElementById("showModel").remove();
};
</script>
<style lang="less">
.show-model {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.65);
  display: flex;
  .show-model-body {
    margin: auto;
    position: relative;
    z-index: 2;
    width: 760px;
    min-height: 600px;
    padding: 40px;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    > div {
      width: 50%;
    }
    .left {
      &.loading {
        display: flex;
        position: relative;
        &:after {
          display: block;
          content: "";
          width: 90%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background: rgba(100, 100, 100, 0.15);
          z-index: 3;
        }
      }
      iframe {
        height: 540px;
      }
    }
    .right {
      text-align: center;
      h4 {
        font-size: 24px;
      }
      #qrcode {
        margin: 24px auto 12px;
        display: flex;
        justify-content: center;
      }
      button {
        margin-top: 100px;
        width: 100px;
        height: 32px;
        background-color: var(--blue500);
        color: #fff;
        border: none;
      }
    }
  }
}
.down-enter-from {
  transform: translate3d(0, -75px, 0);
  opacity: 0;
}
.down-enter-active {
  transition: all 0.5s;
}
.down-enter-to {
  transform: none;
  opacity: 1;
}
.loading-div {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>